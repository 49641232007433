import { Link } from "gatsby"
import React from "react"

import Container from "../../../components/container/container"
import Icon from "../../../components/icon/icon"
import Section from "../../../components/section/section"
import TextBlock from "../../../components/text-block/text-block"

import { RiDeviceLine, RiStore2Line, RiSearchLine, RiFile3Line, RiFileInfoLine, RiDownloadLine, RiQuestionLine, RiFileEditLine } from 'react-icons/ri';

const WebdevServicesBlock = ({ darkBackground, backgroundColor }) => (
    <Section
        id="webdev-services"
        darkBackground={darkBackground}
        style={{ backgroundColor }}
    >
        <Container type="wide">
            <div className="webdev-services-content">
                <TextBlock
                    title="Webdevelopment diensten."
                >
                    {/* Het maken van een goede eerste indruk is erg belangrijk. Vergroot je zichtbaarheid en laat jezelf opvallen bij uw doelgroep. */}
                </TextBlock>
                <div className="webdev-services-grid">
                    <div className="webdev-service webdev-service-1" id="responsive-website">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiDeviceLine />} />
                            </div>
                            <div className="service-title"><h3>Responsive website </h3></div>
                        </div>
                        <div className="service-description">
                            <p> Het internet groeit snel. Waar we vorig decennium websites voornamelijk bezochten via de computer, doen we dat tegenwoordig op veel meer manieren. Een website moet zich tegenwoordig aan kunnen passen aan het schermformaat van de bezoeker. <br/><br/>
                            Voor een perfecte weergave op onder andere <strong>computers, tablets en smartphones</strong> bouwen wij uw website volledig responsive met gebruiksvriendelijkheid in het achterhoofd. Wij maken gebruik van de nieuwste technieken zodat uw website snel, veilig en betrouwbaar is én volledige <strong>geoptimaliseerd voor zoekmachines</strong> zoals Google.</p></div>
                    </div>
                    <div className="webdev-service webdev-service-2" id="wordpress">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiFileEditLine />} />
                            </div>
                            <div className="service-title"><h3>Wordpress</h3></div>
                        </div>
                        <div className="service-description">
                            <p> Bedrijven en merken willen continu vernieuwen. Steeds meer websites worden dynamisch van content. Bedrijven willen op hun website teksten aanpassen, afbeeldingen toevoegen en nieuwe pagina's creeëren. Maar om voor elke aanpassing een webdeveloper in the huren blijft een dure zaak. <br/><br/>
                            Daarom maken wij bij digitam gebruik van <strong>Wordpress</strong>. Wordpress is een content management system die ervoor zorgt  dat website beheerders hun websites kunnen aanpassen <strong>zonder technische kennis</strong>. </p></div>
                    </div>
                    <div className="webdev-service webdev-service-3" id="webshop">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiStore2Line />} />
                            </div>
                            <div className="service-title"><h3>Webshop (E-Commerce) </h3></div>
                        </div>
                        <div className="service-description">
                            <p>Steeds meer winkels sluiten hun fysieke vestiging om hun producten puur online te gaan verkopen. Deze webwinkels (ook wel e-commerce sites genoemd) zijn erg <strong>goedkoop, gemakkelijk te onderhouden</strong> en het stelt u in staat om <strong>vanuit uw huis</strong> een ondernemer te worden.<br/><br/>
                            Bij digitam ontwerpen en bouwen wij opvallende en gebruiksvriendelijke webshops. Wij bouwen onze webshops op een stevige basis van <strong>Wordpress</strong> samen met <strong>Woocommerce</strong>. Hierdoor bent u in staat om zelf pagina's, producten en productcategorieën toe te voegen.</p></div>
                    </div>
                    <div className="webdev-service webdev-service-4" id="SEO">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiSearchLine />} />
                            </div>
                            <div className="service-title"><h3>Search Engine Optimalization (SEO)</h3></div>
                        </div>
                        <div className="service-description">
                            <p>Als bedrijf wilt u natuurlijk zo veel mogelijk mensen bereiken. Het helpt dan om <strong>hoog in de zoekresulaten</strong> van Google te komen staan. Dit betekent simpelweg dat meer mensen uw website zien, waardoor u meer potentiële bezoekers krijgt.
                            <br/><br/>
                            Een website bovenaan in de zoekresulaten laten verschijnen is niet simpel. Allereerst moet uw website <strong>snel</strong> en <strong>veilig</strong> zijn. Daarnaast moet uw website <strong>goede content</strong> hebben, gebruik maken van <strong>metatags</strong>, een goed geconfigureerd <strong>robots.txt</strong> bestand en een juist geformateerde <strong>sitemap</strong>. Bij digitam analyseren wij uw site en lossen wij problemen op die uw posititie in de zoekresulaten benadelen.     </p></div>
                    </div>
                    <div className="webdev-service webdev-service-5" id="static-sites">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiFileInfoLine />} />
                            </div>
                            <div className="service-title"><h3>Statische website </h3></div>
                        </div>
                        <div className="service-description">
                            <p>Een goedkope manier om u online zichtbaar te maken is met een statische website. Dit is een website die niet van content veranderd en volledige gebruikt maakt van server side rendering. Deze websites zijn ideaal voor <strong>kleine bedrijven</strong>, om informatie te geven over hun bedrijf, en hoe de bezoekers contact kunnen opnemen. Statische websites zijn sneller dan dynamische websites, waardoor ze vaak sneller een hogere ranking krijgen in de zoekresulaten
                            <br/><br/>
                            Bij digitam bouwen wij statische websites met onder andere <strong>GatsbyJS</strong> en <strong>NextJS</strong>. Deze frameworks gebruiken slimme trucjes website assets te comprimeren, waardoor uw site nóg sneller wordt.</p></div>
                    </div>
                    <div className="webdev-service webdev-service-6" id="PWA">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiDownloadLine />} />
                            </div>
                            <div className="service-title"><h3>Progressive Web App (PWA)</h3></div>
                        </div>
                        <div className="service-description">
                            <p>Progressive Web Apps zijn de <strong>toekomst van het internet</strong>. Een PWA maakt gebruik van moderne webmogelijkheden om een native app-achtige gebruikerservaring te bieden. Websites die geschikt zijn voor PWA zijn met één klik op de knop te <strong>installeren</strong> op Android, iOS, maar ook op Windows en Mac computers. Deze apps hebben dan ook een app-icoon op het startscherm, kunnen notificaties versturen naar de gebruikers en zijn volledig offline te gebruiken.
                            <br/><br/>
                            Wij van digitam geloven dat PWA's de toekomst zijn. Heeft u een bestaande website die u geschikt wilt maken voor PWA, of bent u geinteresserd in de mogelijkheden? Neem dan nu <a href="#contact">contact</a> op. </p></div>
                    </div>
                    <div className="webdev-service webdev-service-7" id="speciale-wensen">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiQuestionLine />} />
                            </div>
                            <div className="service-title"><h3>Speciale wensen? </h3></div>
                        </div>
                        <div className="service-description">
                            <p>Heeft u speciale wensen? Geen probleem, digitam staat voor u klaar. Neem contact met ons op voor meer informatie, geheel vrijblijvend. Contact opnemen kan door een email te sturen, of het formulier in te vullen.</p> <br /> <p>Bent u een <strong>non-profitorganisatie</strong>, of een <strong>goed doel</strong>? Neem contact met ons op voor een speciale service. </p>
                            <div className="service-link">
                                <Link to="/contact">Neem contact op</Link>
                            </div></div>

                    </div>
                </div>

            </div>
        </Container>
    </Section>
)

export default WebdevServicesBlock
