import React from "react"

import Container from "../../../components/container/container"
import Section from "../../../components/section/section"
import TextBlock from "../../../components/text-block/text-block"

import Photoshop from "../../../images/website/adobe/new-style/photoshop.svg"
import Illustrator from "../../../images/website/adobe/new-style/illustrator.svg"
import Xd from "../../../images/website/adobe/new-style/xd.svg"
import Indesign from "../../../images/website/adobe/new-style/indesign.svg"

import Html5 from "../../../images/website/web-development/html5.svg"
import Css3 from "../../../images/website/web-development/css3.svg"
import Js from "../../../images/website/web-development/js.svg"
import Php from "../../../images/website/web-development/php.svg"
import Python from "../../../images/website/web-development/python.svg"
import Wordpress from "../../../images/website/web-development/wordpress.svg"

const WebdevLandingBlock = ({ darkBackground, backgroundColor }) => (
    <Section
        id="webdev-landing"
        darkBackground={darkBackground}
        style={{ backgroundColor }}
    >
        <Container type="default">
            <div className="webdev-landing-content">
                <TextBlock
                    title="Web development."
                    subtitle="Responsive websites vanaf €250!"
                    size="big"
                />
            </div>
        </Container>
        <div className="webdev-landing-background">

        </div>
    </Section>
)

export default WebdevLandingBlock
