import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactBlock from "../blocks/general-blocks/contact-block/contact-block"

import WebdevLandingBlock from "../blocks/webdev-blocks/webdev-landing-block/webdev-landing-block"
import WebdevServicesBlock from "../blocks/webdev-blocks/webdev-services-block/webdev-services-block"
// import GraphicsServicesBlock from "../blocks/graphics-blocks/graphics-services-block/graphic-services-block"


import { colors } from "../imports/colors"

const WebDevelopmentPage = () => (
    <Layout>
        <SEO
            title="Web development"
            description="Op zoek naar een goed uitziende en robuuste website? Bij digitam maken we geweldige websites, voor een leuke prijs!"
            lang="nl"
            cannonicalUrl="https://digitam.nl/web-development"
        />
        <WebdevLandingBlock darkBackground={false} backgroundColor={colors["grey-00"]} />
        <WebdevServicesBlock darkBackground={false} backgroundColor={colors["grey-00"]} />
        {/* <GraphicsServicesBlock darkBackground={false} backgroundColor={colors["grey-00"]} /> */}
        <ContactBlock darkBackground={true} backgroundColor={colors["blue-05"]}>
            Neem contact met ons op voor meer informatie, geheel vrijblijvend. <br />
            Contact opnemen kan door een email te sturen, of het formulier in te vullen.
        </ContactBlock>

    </Layout>
)

export default WebDevelopmentPage
